import { Guid } from '@/common/models/Guid';
import { ImageDataModel } from '@/common/models/ImageDataModel';

import { OptionModel } from '../OptionModel';

export class FormFieldOption {
  id: Guid;
  label: string;
  value: string;
  order: number;
  imageUrl: string;

  get Image(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.imageUrl);
  }

  constructor(props?: Partial<FormFieldOption>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
  }

  toOptionModel(): OptionModel {
    return {
      label: this.label,
      value: this.id.toString()
    };
  }
}

export const DropdownDefaultOptions = [
  { label: 'Placeholder Text', value: 'Placeholder' },
  { label: 'Default Option', value: 'Option' }
];
